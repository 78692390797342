.py-05 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.markdown {
  overflow-wrap: break-word;
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
  font-size: 1.25rem;
  line-height: 1.5;
}

@media (width >= 768px) {
  .markdown {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.markdown li + li {
  margin-top: .25rem;
}

.markdown p + p {
  margin-top: .75rem;
}

.markdown blockquote > p + p {
  margin-top: .5rem;
}

.markdown strong {
  font-weight: 600;
}

.markdown a {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
  text-decoration-line: underline;
}

.markdown strong a {
  font-weight: 700;
}

.markdown h1 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.25;
}

.markdown h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.25;
}

.markdown h3 {
  margin-top: 2rem;
  margin-bottom: .25rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.375;
}

.markdown h4 {
  margin-top: 2rem;
  margin-bottom: .25rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
}

.markdown h5 {
  margin-top: 2rem;
  margin-bottom: .25rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
}

.markdown h6 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  margin-top: 2rem;
  margin-bottom: .25rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25;
}

.markdown blockquote {
  --tw-border-opacity: 1;
  border-left-width: 4px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  margin-top: .5rem;
  margin-bottom: 1.5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (width >= 768px) {
  blockquote {
    padding-left: 3rem;
  }
}

.markdown code {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  border-radius: .25rem;
  padding: .25rem;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: .875rem;
  line-height: 1.25rem;
  display: inline;
}

@media (width >= 768px) {
  code {
    white-space: nowrap;
    overflow-x: scroll;
  }
}

.markdown pre {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  border-radius: .25rem;
  padding: 1rem;
}

.markdown pre > code {
  border-radius: 0;
  padding: 0;
  display: block;
  overflow: visible;
}

.markdown ul {
  margin-top: .5rem;
  margin-bottom: 1.5rem;
  padding-left: 2rem;
  list-style-type: disc;
}

.markdown ol {
  margin-top: .5rem;
  margin-bottom: 1.5rem;
  padding-left: 2rem;
  list-style-type: decimal;
}

.markdown kbd {
  vertical-align: middle;
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: .25rem;
  padding: .125rem .25rem;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1rem;
  display: inline-block;
}

.markdown table {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
}

.markdown th, .markdown td {
  border-width: 1px;
  padding: .25rem .75rem;
}

.markdown .highlight pre {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}

.markdown video {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.resp-sharing-button__link, .resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  color: #fff;
  margin: .5em;
  text-decoration: none;
}

.resp-sharing-button {
  border-radius: 5px;
  padding: .5em .75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  transition: all 25ms ease-out;
}

.resp-sharing-button__icon svg {
  vertical-align: top;
  width: 1em;
  height: 1em;
  margin-right: .4em;
}

.resp-sharing-button--small svg {
  vertical-align: middle;
  margin: 0;
}

.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

.resp-sharing-button__icon--solid, .resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #f60;
}

.resp-sharing-button--hackernews:hover, .resp-sharing-button--hackernews:focus {
  background-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
}

.resp-sharing-button--vk:hover {
  background-color: #43648c;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover, .resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover, .resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--vk {
  background-color: #507299;
  border-color: #507299;
}

.resp-sharing-button--vk:hover .resp-sharing-button--vk:active {
  background-color: #43648c;
  border-color: #43648c;
}

.resp-sharing-button--telegram {
  background-color: #54a9eb;
}

.resp-sharing-button--telegram:hover {
  background-color: #4b97d1;
}

code[class*="language-"], pre[class*="language-"] {
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  color: #c3cee3;
  tab-size: 2;
  -webkit-hyphens: none;
  hyphens: none;
  background: #263238;
}

code[class*="language-"]::-moz-selection {
  background: #363636;
}

pre[class*="language-"]::-moz-selection {
  background: #363636;
}

code[class*="language-"] ::-moz-selection {
  background: #363636;
}

pre[class*="language-"] ::-moz-selection {
  background: #363636;
}

code[class*="language-"]::-moz-selection {
  background: #363636;
}

pre[class*="language-"]::-moz-selection {
  background: #363636;
}

code[class*="language-"] ::-moz-selection {
  background: #363636;
}

pre[class*="language-"] ::-moz-selection {
  background: #363636;
}

code[class*="language-"]::selection, pre[class*="language-"]::selection, code[class*="language-"] ::selection, pre[class*="language-"] ::selection {
  background: #363636;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .2em;
  padding: .1em;
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1.25em 1em;
  position: relative;
  overflow: auto;
}

[class*="language-"] .namespace {
  opacity: .7;
}

.language-css > code, .language-sass > code, .language-scss > code {
  color: #fd9170;
}

.token.atrule {
  color: #c792ea;
}

.token.attr-name {
  color: #ffcb6b;
}

.token.attr-value, .token.attribute {
  color: #c3e88d;
}

.token.boolean {
  color: #c792ea;
}

.token.builtin {
  color: #ffcb6b;
}

.token.cdata, .token.char {
  color: #80cbc4;
}

.token.class {
  color: #ffcb6b;
}

.token.class-name, .token.color {
  color: #f2ff00;
}

.token.comment {
  color: #546e7a;
}

.token.constant {
  color: #c792ea;
}

.token.deleted {
  color: #f07178;
}

.token.doctype {
  color: #546e7a;
}

.token.entity {
  color: #f07178;
}

.token.function {
  color: #c792ea;
}

.token.hexcode {
  color: #f2ff00;
}

.token.id, .token.important {
  color: #c792ea;
  font-weight: bold;
}

.token.inserted {
  color: #80cbc4;
}

.token.keyword {
  color: #c792ea;
  font-style: italic;
}

.token.number {
  color: #fd9170;
}

.token.operator {
  color: #89ddff;
}

.token.prolog {
  color: #546e7a;
}

.token.property {
  color: #80cbc4;
}

.token.pseudo-class, .token.pseudo-element {
  color: #c3e88d;
}

.token.punctuation {
  color: #89ddff;
}

.token.regex {
  color: #f2ff00;
}

.token.selector {
  color: #f07178;
}

.token.string {
  color: #c3e88d;
}

.token.symbol {
  color: #c792ea;
}

.token.tag, .token.unit {
  color: #f07178;
}

.token.url {
  color: #fd9170;
}

.token.variable {
  color: #f07178;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.bottom-0 {
  bottom: 0;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-auto {
  margin-top: auto;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-6\/10 {
  height: 60vh;
}

.h-64 {
  height: 16rem;
}

.h-75\/100 {
  height: 75vh;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-112 {
  max-height: 28rem;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.flex-grow {
  flex-grow: 1;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-12 {
  gap: 3rem;
}

.overflow-hidden {
  overflow: hidden;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.border-b {
  border-bottom-width: 1px;
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-fb-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 89 152 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-telegram-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(84 169 235 / var(--tw-bg-opacity));
}

.bg-twitter-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(85 172 238 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.fill-current {
  fill: currentColor;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.object-top {
  -o-object-position: top;
  object-position: top;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-serif {
  font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.leading-normal {
  line-height: 1.5;
}

.tracking-normal {
  letter-spacing: 0;
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.no-underline {
  text-decoration-line: none;
}

.opacity-50 {
  opacity: .5;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

a {
  text-decoration-line: underline;
}

.hero {
  margin-bottom: 2rem;
  font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (width >= 768px) {
  .hero {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

.hover\:bg-fb-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(45 67 115 / var(--tw-bg-opacity));
}

.hover\:bg-telegram-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 151 209 / var(--tw-bg-opacity));
}

.hover\:bg-twitter-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(39 149 233 / var(--tw-bg-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

@media (width >= 640px) {
  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

@media (width >= 768px) {
  .md\:-mt-32 {
    margin-top: -8rem;
  }

  .md\:-mt-64 {
    margin-top: -16rem;
  }

  .md\:h-75\/100 {
    height: 75vh;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:p-24 {
    padding: 6rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

/*# sourceMappingURL=404.7983fd00.css.map */

@import 'markdown.pcss';
@import 'sharing.pcss';
@import 'prism-material-oceanic.pcss';

@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  @apply underline;
}

.hero {
  @apply font-serif text-2xl mb-8;
}

@screen md {
  .hero {
    @apply text-3xl;
  }
}